@import url("https://fonts.googleapis.com/css?family=Poppins|Homemade+Apple&display=swap");

$primary-color: rgba(0,62,203,0.98);
$font1: "Poppins", sans-serif;
$signature: 'Homemade Apple', cursive;

body {
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
  color: #444;
  margin: 0;
}

h1,h2,h3,h4,h5 {
  font-family: $font1;
}

.header {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;	
  text-align: center;
  overflow: hidden;
  clear: both;
  .hero {
    width: 90%;
    text-align: center;
	  figure.image {
  		img {
  			width: 170px;
  			height: 170px;
  			border: solid 7px rgba(255,255,255,0.4);
  			margin: auto;
  		}
  	}
    h1 {
      font-family: $font1;
      font-size: 1.8rem;
      font-weight: 600;
  	  margin-top: 20px;
      margin-bottom: 0px;
    }
    .position {
      color: rgba(255,255,255,0.6);
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 30px;
      margin-top: 5px;
    }
  	.socials {
  		margin-top: 20px;
  		a {
  			width: 40px;
  			height: 40px;
  			font-size: 18px;
  			display: inline-block;
        color: rgba(255,255,255,0.4);
  			background-color: rgba(0,0,0,0.1);
  			border-radius: 50%;
  			padding-top: 6px;
  			transition: transform .5s ease-in-out;
  			margin: 0 4px;
  			&:hover {
  				transform: scale(-1, 1);
  			}
  		}
  	}
  }
  .arrow-down {
    font-size: 1.4rem;
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
  }
  .bg-box {
    background: #000;
    transform: rotate(45deg);
    z-index: -1;
    &.bg-box-1 {
      top: -100px;
      right: -100px;
      width: 400px;
      height: 400px;
    }
    &.bg-box-2 {
      bottom: 70px;
      left: 240px;
      width: 150px;
      height: 150px;
    }
    &.bg-box-3 {
      top: 200px;
      left: 80px;
      width: 80px;
      height: 80px;
    }
    &.bg-box-4 {
      bottom: 100px;
      right: 360px;
      width: 60px;
      height: 60px;
    }
  }
}

.block {
  transition: .4s;
  &:hover {
    box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.06);
    transform: scale(1.07);
    // cursor: pointer;
  }
  a {
    opacity: 1 !important;
  }
}

.dark {
  color: white;
  background: $primary-color;
  .title, .subtitle {
    color: white;
  }
  .block {
    background-color: rgba(255,255,255,0.02);
    border-left: solid 3px rgba(255,255,255,0.25);
    p {
      display: none;
    }
    span:first-child {
      opacity: .5;
    }
    span.date {
      font-size: 85%;
      opacity: .5;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.08);
    }
  }
}

.lite {
  .block {
    background-color: rgba(0,0,0,0.02);
    border-left: solid 3px rgba(0,0,0,0.25);
    h5 {
      color: $primary-color;
    }
    p {
      display: none;
    }
    span:first-child {
      opacity: .5;
    }
    a {
      font-size: 85%;
      opacity: .5;
    }
    &:hover {
      background-color: rgba(0,0,0,0.09);
    }
  }
}

.skills {
  ul {
    margin-left: 0;
    padding-left: 0;
    li {
      display: inline-block;
      border: solid 1px rgba(255,255,255,0.08);
      border-radius: 25px;
      padding: 5px 12px;
      margin: 3px;
      &:hover {
        background-color: rgba(255,255,255,0.06);
        border-color: rgba(255,255,255,0.4);
        cursor: pointer;
      }
    }
  }
}

.signature {
  font-family: $signature !important;
  -ms-transform: rotate(-5deg); /* IE 9 */
  transform: rotate(-5deg);
}

.btn-circle {
  border-radius: 25px;
}

.footer {
  .socials {
    a {
      width: 50px;
      height: 50px;
      font-size: 22px;
      display: inline-block;
      color: rgba(255,255,255,1.0);
      background-color: $primary-color;
      border-radius: 50%;
      padding-top: 8px;
      transition: transform .5s ease-in-out;
      margin: 0 4px;
      &:hover {
        transform: scale(-1, 1);
      }
    }
  }
}

.opacity-0 { opacity: 0; }
.opacity-25 { opacity: .25; }
.opacity-50 { opacity: .5; }
.opacity-75 { opacity: .75; }
.opacity-100 { opacity: 1; }

.credit {
  position: fixed;
  left: -50px;
  bottom: 120px;
  z-index: 10;
  transform: rotate(-90deg);
  p, a {
    color: rgba(255,255,255,0.6);
  }
}

.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: $primary-color;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 1;
  transition: 1s opacity;
  &.loaded {
    opacity: 0;
    visibility: hidden;
  }
  .spinner_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 33333;
  }
  .spinner {
    width: 50px;
    height: 50px;
    background-color: rgb(255, 255, 255);
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
  }
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }

  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }

  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}


@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }

  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@media (min-width: 768px) {
  .header .hero {
    // width: 500px;
    img {
      width: 170px;
      height: 170px;
    }
  }
}